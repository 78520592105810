
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import {
  minLengthRule,
  requiredRule,
  maxLengthRule
} from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "Edit",

  data: () => ({
    minLengthRule,
    maxLengthRule,
    requiredRule,
    model: {} as any,
    disableSave: false as boolean,
    fullLoaded: false as boolean,
    errorMessages: {} as { [value: string]: Array<string> }
  }),

  watch: {
    model: {
      deep: true,
      handler() {
        if (this.fullLoaded) {
          this.disableSave = false;
        }
      }
    }
  },

  computed: {
    computedRules(): object {
      return {
        min: this.minLengthRule.bind(this),
        required: this.requiredRule.bind(this),
        max: this.maxLengthRule.bind(this)
      };
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const item = await API.workPositions().getForEdit(Number(to.params.id));

      next(vm => {
        vm.model = { icon: {}, ...item };

        vm.$nextTick(() => {
          vm.fullLoaded = true;
        });
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as any).validate()) {
          const model = { ...this.model };

          for (const key in model) {
            if (key === "icon" && typeof model[key] === "string") {
              delete model[key];
            }
          }

          await this.$API
            .workPositions()
            .edit(Number(this.$route.params.id), model);
          await this.$router.push("/work-positions");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
